/*
 * @Author: your name
 * @Date: 2021-08-13 14:05:11
 * @LastEditTime: 2021-08-16 13:40:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qiniu-edu\src\router\routes.js
 */
import loadable from '@loadable/component'

const routes = [{
    path: '/',
    exact: true,
    component: loadable(() => import('@/view/index/index')),
  },
  {
    path: '/course-center',
    exact: true,
    component: loadable(() => import('@/view/course-center/course-center')),
  },
  {
    path: '/about-us',
    exact: true,
    component: loadable(() => import('@/view/about-us/about-us')),
  },
  {
    path: '/course-detail',
    exact: true,
    component: loadable(() => import('@/view/course-detail/course-detail')),
  },
  {
    path: '/qiyu',
    exact: true,
    component: loadable(() => import('@/view/qiyu/qiyu')),
  },
  {
    path: '/whitePaper',
    exact: true,
    component: loadable(() => import('@/view/whitePaper'))
  },
  {
    path: '/commonProblems',
    exact: true,
    component: loadable(() => import('@/view/commonProblems'))
  },
  {
    path: '/qiniuDay',
    exact: true,
    component: loadable(() => import('@/view/qiniuDay'))
  },
  {
    path: '/protection',
    exact: true,
    component: loadable(() => import('@/view/protection'))
  },
  {
    path: '/internetMeeting',
    exact: true,
    component: loadable(() => import('@/view/internetMeet'))
  },
  {
    path: '/jjgj',
    exact: true,
    component: loadable(() => import('@/view/course-detail/jjgj')),
  },
  {
    path: '/jjtz',
    exact: true,
    component: loadable(() => import('@/view/course-detail/jjtz')),
  },
  {
    path: '/gpgj',
    exact: true,
    component: loadable(() => import('@/view/course-detail/gpgj')),
  },
  {
    path: '/gptz',
    exact: true,
    component: loadable(() => import('@/view/course-detail/gptz')),
  },
  {
    path: '/cbfx',
    exact: true,
    component: loadable(() => import('@/view/course-detail/cbfx')),
  },
  {
    path: '/bxsz',
    exact: true,
    component: loadable(() => import('@/view/course-detail/bxsz')),
  },
]

export default routes
